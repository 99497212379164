<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active"><a href="#">Home</a></li>
          <li><a href="javascript:void(0);">Account Verification </a></li>
        </ul>
      </div>
    </div>
    <section class="section--order-tracking">
      <div class="container">
        <h2 class="page__title"></h2>
        <div class="order-tracking__content">
          <div class="order-tracking__form">
            <form>
              <div class="form-row">
                <div class="col-12">
                  <p>
                    <b>Verify your email address</b><br />
                    We now need verify your email address. we've sent an email
                    to
                    <i
                      ><b>{{ emailResent.email }}</b></i
                    >
                    to verify your address please click the link in that email
                    to continue
                  </p>
                </div>
                <div class="col-12 text">
                  <img
                    class="okpng mx-auto d-block"
                    src="../../../assets/img/ok.png"
                    alt="ok"
                  />
                </div>
                <div class="col-12 form-group-block">
                  <router-link
                    class="btn ps-button"
                    :to="{ name: 'login' }"
                    v-if="!isAuthenticated"
                  >
                    Login
                  </router-link>
                  <div v-if="isAuthenticated">
                    <button
                      class="btn ps-button"
                      type="submit"
                      @click="resentCode(emailResent.email)"
                    >
                      Resend
                    </button>
                  </div>
                  <!-- {{ timerCount }} -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      emailResent: {
        email: "",
      },

      // timerCount: 10,
    };
  },

  computed: {
    ...mapGetters("auth", ["user", "isAuthenticated"]),
  },

  mounted() {
    if (this.$route.params) {
      if (this.$route.params.email) {
        this.emailResent.email = this.$route.params.email;
      }
    }
  },

  methods: {
    ...mapActions("auth", ["resendVerifycode"]),

    resentCode() {
      this.resendVerifycode(this.emailResent);
      // .then(() => {
      //   this.$router.push({ name: "login" });
      // });
    },
  },
  // watch: {
  //   timerCount: {
  //     handler(value) {
  //       if (value > 0) {
  //         setTimeout(() => {
  //           this.timerCount--;
  //         }, 1000);
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
};
</script>
<style lang="scss" scoped>
.icon-check {
  width: 300px;
}
.okpng {
  width: 100px;
}
</style>